<template>
    <v-card class="worker" flat>
        <v-img
            :src="item.img"
            height="260"
            position="top"
        ></v-img>
        <v-card-title class="pa-0 mt-3">
            <div class="title text-20-60">
                {{ item.name }}
            </div>
        </v-card-title>
        <v-card-subtitle class="pa-0 my-3">
            <div class="text-14 silver--text">
                {{ item.post }}
            </div>
        </v-card-subtitle>
        <v-card-text class="pa-0 d-md-none">
            <div class="text-16 description">
                {{ item.desc }}
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: 'Worker',
    props: ['item']
}
</script>

<style lang="scss">
.worker{
    padding: 10px;
    .v-card__title{
        padding: 0;
        .title::before{
            content: "";
            border-left: 3px solid #5496F0;
            margin-right: 15px;
        }
    }
    .description{
        width:100%;
    }
    @media all and (max-width: 600px){
        .description{
            width: 80%;
        }
    }
}
</style>
