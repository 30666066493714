<template>
    <div class="about">
        <layout class="xs-bg-light" :xswhite="true">
            <template v-slot:header>
                <div class="white">
                    <div class="image">
                        <img v-if="$vuetify.breakpoint.smAndDown" src="@/assets/icons/about/world-sm.svg" alt="" class="" style="opacity:0.45">
                        <img v-if="$vuetify.breakpoint.mdAndUp" src="@/assets/icons/about/world.svg" alt="" class="">
                    </div>
                    <v-container class="index-1">
                        <breadcrumbs :items="breadcrumbs" :large="true" class="py-7 d-none d-md-block"></breadcrumbs>
                        <h1 class="heading-1 mb-sm-6 mt-2 mt-sm-0">About us</h1>
                        <v-col cols="12" sm="8" lg="5" class="pl-0 mb-10">
                            <p class="font-16">
                                Freight Crew is a full cycle, quality transportation company specialized in making time-limited deliveries in the United States, Canada, and Mexico. We are a team of forward-thinking shipment professionals, who will develop the individual transport solutions for your business. Our clients can focus on their company’s goals while we ensure the highest level of transportation service and find the best freight solutions for their business.
                            </p>
                        </v-col>
                    </v-container>
                </div>
            </template>
            <template v-slot:main>
                <aircraft-city/>
                <div class="second-silver py-16">
                    <v-container>
                        <h1 class="heading-2 mb-6">Our Values</h1>
                        <v-row>
                            <v-col cols="12" sm="6" lg="3">
                                <star-card color="bg-transparent">
                                    <template v-slot:title>
                                        Responsibility
                                    </template>
                                    <template v-slot:description>
                                        We do whatever it takes to fulfill the clients’ needs and deliver on our commitments. We support and share responsibility with the members of our team
                                    </template>
                                </star-card>
                            </v-col>
                            <v-col cols="12" sm="6" lg="3">
                                <star-card color="bg-transparent">
                                    <template v-slot:title>
                                        Collaboration
                                    </template>
                                    <template v-slot:description>
                                        We are enthusiastic collaborators. In Freight Crew we believe that together we are stronger. We are working directly with transportation companies and airlines, to deliver the best service possible
                                    </template>
                                </star-card>
                            </v-col>
                            <v-col cols="12" sm="6" lg="3">
                                <star-card color="bg-transparent">
                                    <template v-slot:title>
                                        Safety and reliability
                                    </template>
                                    <template v-slot:description>
                                        We highly appreciate the trust of our clients and partners, so we provide safest freight solutions. We seek out and invest in the most modern tools, policies, and infrastructure necessary for a safe workplace, safe ride, and safe delivery
                                    </template>
                                </star-card>
                            </v-col>
                            <v-col cols="12" sm="6" lg="3">
                                <star-card color="bg-blue" white="white--text">
                                    <template v-slot:title>
                                        Time-efficiency
                                    </template>
                                    <template v-slot:description>
                                        We try our best to be on time for everything and use everyone's time effectively. We expect our team to avoid wasting time on minor issues and focus on delivering on our commitments
                                    </template>
                                </star-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </div>
                <counter :bgimg="require('@/assets/logo/bg-logo-blue-full.svg')">
                    <h1 class="heading-2 white--text mb-10">More than just another freight service provider</h1>
                </counter>
                <cooperation/>
            </template>
        </layout>
    </div>
</template>

<script>

import Layout from '@/components/layouts/BlueTopLayout.vue'
import Breadcrumbs from '@/components/base/Breadcrumbs.vue'
import StarCard from '@/components/base/StarCard.vue'
import Worker from '@/components/base/Worker.vue'
import AircraftCity from '@/components/pages/AircraftCity.vue'
import Counter from '@/components/pages/Counter.vue'
import Cooperation from '@/components/pages/Cooperation.vue'


export default {
    name: 'Home',
    components: {
        Layout,
        Breadcrumbs,
        AircraftCity,
        StarCard,
        Worker,
        Counter,
        Cooperation
    },
    data: () => ({
        breadcrumbs: [
            {
                text: 'Home page',
                to: 'frontend.home',
                disabled: false,
            },
            {
                text: 'About us',
                disabled: true,
            }
        ],
        workers:
            {
                img: 'https://image.freepik.com/free-photo/happy-manager-office-worker-man-white-background_121837-89.jpg',
                name: 'John Lennon',
                post: 'CEO Freight Crew',
                desc: '«Freight Crew will change the way you think about freight bussines»'
            }

    })
}
</script>
<style lang="scss">
    .white{
        position: relative;
        div.image{
            position: absolute;
            right: 0;
            height: 100%;
            text-align: right;
            img{
                height: 100%;
            }
        }
        @media all and (max-width:600px){
            div.image{
                position: absolute;
                top: 30%;
                right: 10px;
                width: 200px;
                height: 70%;
                img{
                    height: 100%;
                }
            }
        }
    }
    .index-1{
        z-index: 1;
        position: relative;
    }
    .about{
        @media all and (max-width: 600px){
            .heading-2{
                font-weight: 600;
                font-size: 28px;
                line-height: 36px;
            }
            .text-16{
                font-size: 14px;
                line-height: 22px;
            }
            .text-20-60{
                font-weight: 600;
                font-size: 16px;
                line-height: 26px;
            }
            .text-14{
                font-size: 12px;
                line-height: 16px;
            }
        }
    }
</style>
